import {
  PersistedClient,
  Persister,
} from '@tanstack/react-query-persist-client'
import { del, get, set } from 'idb-keyval'

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export default function createIdbPersister(
  idbValidKey: IDBValidKey = 'reactQuery'
) {
  return {
    persistClient: async (client: PersistedClient) => {
      const excludedKeys: string[] = ['map-listings', 'disable-cache']
      set(idbValidKey, {
        ...client,
        clientState: {
          ...client.clientState,
          queries: client.clientState.queries.filter((q) => {
            return !q.queryKey.find((k: any) => excludedKeys.includes(k))
          }),
        },
      }).catch(() => {
        console.info('Storage failure, clearing indexdb cache')
        del(idbValidKey)
      })
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await del(idbValidKey)
    },
  } as Persister
}
